.parallax-v1 {
	position: relative;

	@media (min-width:768px) {

		background-image: url(RESOURCE/img/parallax3.webp);
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 350px;

		@media (max-width:767px) {
			background-attachment: scroll;
		}


	}

	.parallax-box {
		padding: 20px;
		background-color: var(--color-secondary);
		padding: 20px;

		@media (min-width:768px) {
			height: 390px;
			margin-top: -20px;
			margin-left: 10px;
			display: flex;
			vertical-align: middle;
			flex-direction: column;
			justify-content: space-around;
		}

		.title {
			color: var(--color-white);
		}

		.text {
			color: var(--color-white);
		}
	}

}