.unit-service-v1 {
	/*background-color: var(--color-secondary-light);*/
	background-color: var(--color-grey-light);
	/*margin-top: 15px;*/
	border-radius: var(--border-radius);
	border: 1px solid var(--color-border);

	.widget-image {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.info {
		li {
			position: relative;
			padding-left: 25px;

			.far {
				margin-right: 5px;
				position: absolute;
				left: 0;
				top: 5px;
			}
		}
	}


	.title {
		font-weight: 700;
	}

	.image {
		img {
			width: 100%;
			object-fit: cover;
			border-radius: var(--border-radius);

		}
	}

	.info {
		padding: 10px;
	}

	.fa {
		padding-right: 5px;
	}


}