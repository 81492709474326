.faq-v1 {
	margin-top: var(--page-margin-top);

	.accordion-item:first-of-type .accordion-button {
		border-radius: 0;
	}

	.accordion-item {
		margin-bottom: 10px;
		border: 1px solid var(--bs-accordion-border-color);
		border-radius: 5px !important;
	}

	.accordion-button:not(.collapsed) {
		color: var(--color-black);
		background-color: var(--color-white);
	}

	.accordion-button:not(.collapsed):after {
		filter: brightness(0) saturate(100%) invert(0%) sepia(5%) saturate(0%) hue-rotate(330deg) brightness(99%) contrast(106%);
	}

	.inner-banner {
		&.faq {
			background-image: url(RESOURCE/img/inner-banner-faq.webp);
		}
	}

	.title {
		font-size: 1.4rem;
		font-size: var(--h3-font-size);
		padding: 10px 3px;
		font-weight: 600;
		margin-bottom: 10px;
		margin-top: 10px;
		background-color: var(--color-secondary);
		color: var(--color-white);
		text-indent: 5px;
	}

	.accordion-button {
		font-size: 20px;
		background-color: var(--color-white) !important;
		border-radius: 5px !important;
	}

	ul,
	ol {
		margin-left: 15px;
	}
}