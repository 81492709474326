.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.kuk-seo-text {
		@media(min-width:993px) {
			column-count: 2;
		}
	}

	.blogcontent {
		.content-col {
			.h4 {
				@media(min-width:993px) and (max-width:1200px) {
					min-height: 74px;
				}

				&.lead-text {
					min-height: 50px;
				}
			}

			p {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;

			}
		}
	}

	.feature-bar-v1 {
		background-color: #f8e1c2;
	}

	.feature-slide {
		img {
			width: 24px;
		}

		.owl-dots {
			margin: 0 auto;

			.owl-dot {
				width: 10px;
				height: 10px;
				margin-bottom: 8px;
			}
		}
	}

	.feature-bar {
		@media(max-width: 768px) {
			height: 68px;
		}
	}



	.partner-bl-box {

		.btn {
			display: block;
			margin: 0 auto;
			max-width: 140px;

			@media (min-width:993px) {
				display: none;
			}
		}

		.partner-logo-bl {
			.logo-item {
				padding: 10px;
				border: 1px solid #ddd;
			}

			.owl-carousel .owl-item img {
				height: 100px;
				width: inherit;
				display: inline-block;
			}
		}
	}

	.unitofday-units {
		@media (min-width:992px) {
			margin: 0 -15px;
			display: flex;
			flex-wrap: wrap;
		}

		.col-lg-4 {
			@media (min-width:992px) {
				-webkit-box-flex: 0;
				flex: 0 0 auto;
				padding: 0 15px;
			}
		}
	}

	.unitofday-units {
		@media (max-width:992px) {
			.owl-prev {
				position: absolute;
				top: 38%;
				left: 6px;
			}
		}

		@media (max-width:992px) {
			.owl-prev span {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: #fff;
				font-size: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-bottom: 9px;
			}
		}

		@media (max-width:992px) {
			.owl-next {
				position: absolute;
				top: 38%;
				right: 6px;
			}
		}

		@media (max-width:992px) {
			.owl-next span {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: #fff;
				font-size: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-bottom: 9px;
			}
		}
	}

	.angebote-carousel {
		@media (min-width:992px) {
			justify-content: center;

		}
	}

	.angebote-carousel,
	.unitofday-units {
		position: relative;

		@media (max-width:767px) {
			.owl-prev {
				position: absolute;
				top: 38%;
				left: 6px;
			}
		}

		@media (max-width:767px) {
			.owl-prev span {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: #fff;
				font-size: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-bottom: 9px;
			}
		}

		@media (max-width:767px) {
			.owl-next {
				position: absolute;
				top: 38%;
				right: 6px;
			}
		}

		@media (max-width:767px) {
			.owl-next span {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background-color: #fff;
				font-size: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-bottom: 9px;
			}
		}

		.col-lg-4 {
			@media (min-width:993px) {
				padding: 0 15px;
			}
		}

		@media (min-width:993px) {
			margin: 0 -15px;
			display: flex;
		}

		.col-md-4 {
			@media (min-width:993px) {
				width: 33.33%;
				padding: 0 15px;
			}
		}
	}


	h2 {
		text-align: center;

		p {
			margin-bottom: 0;
		}
	}


	.welcome-bl {
		background-color: var(--color-grey-normal);

		.py-5 {
			@media (max-width:992px) {
				padding-bottom: 0 !important;
			}
		}

		.lead-para {
			font-weight: 600;
			font-size: 18px;

			@media (max-width:767px) {
				display: none;
			}
		}

		.wel-text-li {
			@media (max-width:767px) {
				font-size: 17.64px;
				margin-bottom: 0;
			}
		}

		.description-para {
			/*text-align: justify;*/
			font-size: 18px;
		}

		.wel-text-block {
			font-size: 20px;


			.col-md-6 {
				&:last-child {

					/*border-radius: 5px;*/
					border-left: 1px solid #b6b6b6;

					@media (max-width:767px) {
						/*border-left: none;
						border-top: 1px solid #b6b6b6;
						padding: 25px 0 0;*/
						margin-bottom: 30px;
						border: 0;
					}
				}
			}

		}
	}

	.partner-bl {
		.logo-neme {
			font-size: 1.4rem;
			font-weight: 600;
			font-family: Playfair Display, serif;
		}

		.col5 {
			padding: 0 15px;
			margin-bottom: 30px;

			@media (min-width: 768px) {
				width: 20%;
				-webkit-box-flex: 0;
				flex: 0 0 auto;

			}
		}
	}

	.youtube-bl {
		position: fixed;
		z-index: 99;
		right: 10px;
		bottom: 325px;

		.btn-close {
			background-color: var(--color-white);
			padding: 4px;
			font-size: 9px;
			display: inline-block;
			position: absolute;
			opacity: 1;
			left: -6px;
			cursor: pointer;
			top: -6px;
			display: none;
			box-shadow: 0 0 2px rgba(0, 0, 0, .5);
			border-radius: 50%;

			&.show {
				display: inline-block;
			}
		}

		.body {
			overflow: hidden;
			padding: 0;
		}

		.box {
			height: 608px;
			background-color: transparent;
			border: none;
			box-shadow: inherit;

			@media (max-width:550px) {
				height: 338px;
			}
		}

		.header {

			position: absolute;
			top: 0;
			right: 0;
			z-index: 5;
			border: none;
			background-color: #fff;
			text-align: center;

			border-radius: 50%;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.header-close {
				right: 10px;
				top: -3px;
			}
		}

		.video-space {
			position: relative;
			padding-bottom: 70.5%;
			/* 16:9 */
			padding-top: 25px;
			height: 0;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	.banner-bl {
		position: relative;
	}

	.video-popup {
		background-color: transparent;
		border: none;
		opacity: 0;
		visibility: hidden;
		width: 220px;
		text-align: center;
		display: none;

		@media (max-width:767px) {
			width: 130px;
		}

		&.show {
			opacity: 1;
			visibility: visible;
			display: inline-block;
		}

		.video-text-bl {
			color: var(--color-white);
			font-weight: 700;
			padding: 7px;
			border-radius: 5px;
			display: inline-block;
			background-color: var(--btn-primary-bg-color);
			margin-bottom: 0;
			transform: rotate(-90deg);
			right: -47px;
			bottom: 40px;
			position: absolute;
			line-height: 1.1;

			&:before {
				content: "";
				width: 0;
				height: 0;
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid var(--color-primary);
				position: absolute;
				left: 50%;
				top: -8px;
				transform: translate(-50%, 0);

			}
		}

		.video-popup-image {
			img {
				border-radius: 5px
			}
		}


		.video-popup-content {
			position: absolute;
			z-index: 2;
			top: 50%;
			/*left: 10px;*/
			width: 100%;
			text-align: center;
			transform: translate(0, -50%);

			span {
				color: var(--color-white);
				font-weight: bold;
				display: block;

				@media(max-width: 600px) {
					font-size: 14px;
				}
			}
		}
	}

	.banner-content {
		position: absolute;
		bottom: 30px;
		width: 100%;
		text-align: center;

		@media (max-width:992px) {
			bottom: 100px;

		}

		.banner-text {
			border-radius: 5px;
			color: var(--color-white);
			text-align: center;
			display: block;
			font-size: 20px;
			padding: 0;
			/* text-shadow: 0 .1rem 1rem rgba(0, 0, 0, .5); */
			margin: 0;



			@media (max-width:992px) {
				font-size: 18px;
			}
		}
	}

	.video-block {

		overflow: hidden;
		position: relative;
		height: calc(100vh - 172px);
		width: 100%;



		@media (max-width:1200px) {
			min-height: 530px;
			position: relative;
			padding-top: calc(100vh - 160px);
			width: 100%;
			overflow: hidden;

		}


	}



	.video {
		max-width: 100%;
		width: 100%;
		position: absolute;
		/*	transform: translateY(-50%);
				top: 50%;*/
		top: 0;
		background-size: contain;
		background-position: center;
		background-color: var(--color-black);
		object-fit: cover;
		height: 100%;

		/*@media (max-width: 1400px) {
					top: 50%;
				}*/


		@media (max-width: 767px) {

			width: 100%;
			max-width: inherit;

		}


	}

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}

		.owl-item {
			@media(max-width: 576px) {
				text-align: center;
			}
		}

		.highlight {

			text-align: left;
			color: var(--color-black);
			padding: 14px 10px 10px 30px;
			position: relative;

			/*@media(max-width: 992px) {
				width: 50%;
			}*/

			@media(max-width: 576px) {
				display: inline-block;
			}

			.icon {
				font-size: 24px;
				color: var(--color-primary);
				position: absolute;
				left: 0px;
				top: 15px;

				&.icon-euro {
					left: 10px;
				}

				&.icon-home {
					left: -3px;
				}
			}

			.title {
				font-size: 15px;
				font-weight: 600;
			}

			.text {
				font-size: 13px;
			}
		}
	}

}