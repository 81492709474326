.newsletter-page {

	.news-topbar {
		text-align: center;
		background-color: #f8e1c2;
		padding: 15px;
		margin-bottom: 20px;

		strong {
			display: block;
		}
	}

	.pt-0-mob {
		@media (max-width:767px) {
			padding-top: 0 !important;
		}
	}

	.feat-row {
		display: flex;
		justify-content: space-between;

		@media (max-width:767px) {
			flex-wrap: wrap;
			width: 100%;
		}

		.feat-icon-bl {
			max-width: 200px;

			i {
				height: 170px;
				max-width: 90px;
				align-items: center;
				justify-content: center;
				display: flex;
				margin: 0 auto;
			}

			img {

				display: block;

				@media (max-width:767px) {
					margin: 0 auto;
				}
			}

			@media (max-width:767px) {
				margin: 0 auto;
				text-align: center;
			}
		}

	}

	h1 {
		font-size: 3rem;
		color: var(--color-secondary);
		line-height: 1.1;

		@media (max-width:992px) {
			font-size: 2rem;
		}
	}

	h4 {
		color: var(--color-primary);
		line-height: 1.1;
	}

	.newslet-bl {
		position: relative;
	}

	.icon-arrow {
		position: absolute;
		width: 50px;
		height: 50px;
		background-image: url(RESOURCE/img/icon-arrow.png);
		background-repeat: no-repeat;
		display: inline-block;
		background-size: cover;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		right: -13px;
		top: -13px;
	}

	.newsletter-slide {
		.owl-nav {
			text-align: center;
			margin-top: 5px;
			padding-left: 15px;

			button {
				width: 30px;

				&.disabled {
					opacity: .35;
				}
			}


			.owl-next {
				color: transparent;

				&:before {
					content: "\f105";
					font-family: var(--fa-style-family, "Font Awesome 6 Pro");
					font-weight: var(--fa-style, 900);
					color: black;
				}
			}

			.owl-prev {
				color: transparent;

				&:before {
					content: "\f104";
					font-family: var(--fa-style-family, "Font Awesome 6 Pro");
					font-weight: var(--fa-style, 900);
					color: black;
				}
			}
		}
	}



	.container {
		&.small {
			@media (min-width:1240px) {
				max-width: 920px;
			}
		}
	}

	.quote-block {
		display: flex;

		@media (max-width:767px) {
			flex-wrap: wrap;
		}
	}

	.show-tab {
		@media (max-width:767px) {
			margin-top: 30px;
		}
	}

	.quote-title {
		width: 26%;
		position: relative;
		top: -65px;

		@media (max-width:767px) {
			width: 100%;
		}

		h4 {
			text-transform: uppercase;
			font-size: 16px;
		}

		h2 {
			font-size: 2rem;
			color: var(--color-secondary);
			line-height: 1.1;
		}
	}

	.tick-mark-li {
		@media (max-width:767px) {
			margin-top: 35px;
		}

		li {
			position: relative;
			padding-left: 30px;
			margin-bottom: 10px;

			&:before {
				background: url(RESOURCE/img/icon-tick.png);
				background-repeat: no-repeat;
				content: "";
				width: 20px;
				height: 20px;
				display: inline-block;
				position: absolute;
				left: 0;
				background-size: 13px;
				background-position: center;
				top: 4px;
			}
		}
	}

	.newsletter-quote {
		position: relative;
		width: 60%;
		height: 260px;

		@media (max-width:992px) {
			height: 380px;
		}

		@media (max-width:767px) {
			width: 100%;
			height: 400px;
		}

		@media (max-width:550px) {
			min-height: 470px;
		}




		#navbuttons {
			width: 20px;
			position: absolute;
			right: -70px;
			bottom: 30px;
			z-index: 2;

			@media (max-width:992px) {
				bottom: 30%;
			}

			@media (max-width:767px) {
				width: 70px;
				position: absolute;
				right: 0;
				bottom: 0;
				text-align: right;
				display: flex;
				justify-content: space-around;
			}

			a {
				color: var(--color-black);
				display: inline-block;
				height: 30px;
			}
		}

		#buttons {
			position: absolute;
			left: -43%;
			bottom: -10px;

			@media (max-width:992px) {
				bottom: 20%;
			}

			@media (max-width:767px) {
				position: absolute;
				left: 0;
				bottom: 0;
			}

			.btn-dot {
				&.active {
					span {
						background: var(--color-secondary);
					}
				}
			}

			span {
				width: 8px;
				height: 8px;
				background: #e1e1e1;
				margin: 0 25px 0 0;
				border-radius: 100px;
				display: inline-block;

			}
		}

		.feedback {
			visiblity: hidden;
			opacity: 0;
			position: absolute;
			padding: 25px 35px 25px;
			background-color: #fff;
			box-shadow: 0 4px 26px rgba(0, 0, 0, .10);
			border-radius: 10px;
			min-height: 235px;
			transition: all .2s ease;

			@media (max-width:992px) {
				min-height: 260px;
			}

			@media (max-width:767px) {
				min-height: 307px;
			}

			span {
				display: block;
				color: #727b81;

				&.cl-name {
					font-weight: bold;
					font-size: 18px;
				}

				&.place {
					font-size: 12px;
				}
			}

			em {
				font-weight: bold;
				margin-bottom: 20px;
				display: block;
				font-size: 16px;
				min-height: 120px;

				@media (max-width:992px) {
					min-height: 144px;
				}

				@media (max-width:767px) {
					min-height: 192px;
				}

				@media (max-width:550px) {
					min-height: 212px;
				}
			}

			&.next-active {
				visiblity: visible;
				opacity: .5;
				transform: translate(30px, 70px);
				z-index: 1;

				@media (max-width:767px) {
					transform: translate(30px, 70px);
				}
			}

			&.active {
				visiblity: visible;
				opacity: 1;
				transform: translate(0px, 0px);
				z-index: 2;
			}
		}




	}
}