.header-boxes {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 30;
}

#header-fastnav-search {
	transition: top 0.5s ease;
	position: absolute;
	top: -100px;
	background-color: var(--color-grey-light);
	z-index: 10;
	right: 0px;

	&.show {
		top: var(--page-margin-top);
	}


	.search-unit-ul {
		max-width: 280px;
		padding: 8px 15px;

		.close {
			text-align: right;
			margin-top: -10px;
			cursor: pointer;
			margin-bottom: -3px;
		}
	}

	.input-search-unit {
		width: 250px;


		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #333;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
		}
	}
}

#tele-box {
	transition: top 0.5s ease;
	position: absolute;
	top: -700px;
	background-color: var(--color-grey-light);
	z-index: 10;
	right: 0px;

	&.show {
		top: var(--page-margin-top);
	}

	.phone-box {
		padding: 10px;
		position: relative;

		.close {
			position: absolute;
			top: 5px;
			right: 5px;
			cursor: pointer;
		}
	}

	.fa {
		padding-right: 5px;
	}

	.phoneLink {
		text-decoration: underline;
		cursor: pointer;
	}
}

#settings {
	transition: top 0.5s ease;
	position: absolute;
	top: -700px;
	background-color: var(--color-bg-light);
	z-index: 10;
	right: 0px;

	&.show {
		top: var(--page-margin-top);
	}

	.settings-box {
		padding: 10px;
		position: relative;

		.close {
			position: absolute;
			top: 5px;
			right: 5px;
			cursor: pointer;
		}
	}

	.fa-sharp {
		padding-right: 5px;
	}

	.link {
		cursor: pointer;

	}
}

.navbar-v1 {

	z-index: 50;

	.header-main .header-logo .logo {
		@media(min-width:1401px) {
			width: 250px !important;
		}

		@media(max-width:1400px) {
			width: 250px !important;
		}

		/*@media(max-width:1400px) {
			width: 295px !important;
		}*/

		@media (max-width:992px) {
			width: 210px !important;
		}

		@media (max-width:450px) {
			width: 165px !important;
		}


	}

	&.affix-top {


		.header-main,
		.navbar-v1.affix .header-main {
			transition: all .2s ease;

		}
	}

	&.affix {


		.header-main,
		.navbar-v1.affix .header-main {
			@media(min-width:993px) {
				padding: 0 !important;
				transition: all .2s ease;
			}
		}
	}
}

.dropdown-2 {
	&:hover {
		.dropdown-toggle {
			border-bottom: 3px solid var(--color-secondary) !important;
		}
	}

	.dropdown-item:active {
		color: var(--color-black);
	}
}

.suchen-drowdown {
	@media(max-width:767px) {
		.dropdown-toggle {
			&.show {
				border-bottom: 3px solid var(--color-secondary) !important;
			}

		}
	}

	.dropdown-item:active {
		color: var(--color-black);
	}
}


.navbar-v1.affix,
.navbar-v1 {
	padding: 15px 0;
	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	/*@media (max-width:768px) {
		position: absolute;

	}*/


	.fav-count {
		position: relative;

		.sub-count {
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 7px;
			width: 18px;
			height: 18px;
			background: var(--color-secondary);
			color: var(--color-white);
			text-align: center;
			font-size: 12px;
			line-height: 18px;
			font-weight: bold;

			@media (min-width:1400px) {
				right: 6px;
			}

			@media (min-width:1800px) {
				right: 10px;
			}

			@media (max-width:1399px) {
				right: 4px;
			}

			@media (max-width:1199px) {
				right: 2px;
			}

			@media (max-width:992px) {
				right: -2px;
			}


			@media (max-width:767px) {
				width: 15px;
				height: 15px;
				line-height: 15px;
				right: -1px;
			}
		}
	}

	.tele-box {
		a {
			padding: 0 !important;
		}
	}

	.navbar-v1.affix .search-unit-ul,
	.navbar-v1.affix {
		position: absolute;
		right: 0;
		top: 110px;
		background-color: var(--color-grey-light);
		max-width: 280px;
		padding: 8px 32px;
		height: 40px;
		font-weight: 600;
		text-align: center;

	}

	.search-unit-ul {

		right: 0;
		background-color: var(--color-grey-light);
		top: 107px;
		max-width: 280px;
		padding: 8px 15px;

		&.dropdown-menu {
			position: absolute !important;

			@media (max-width:992px) {
				width: auto !important;
				top: 25px !important;
				position: absolute !important;
			}
		}

		@media (max-width:992px) {
			top: 77px;
		}
	}

	.input-search-unit {
		width: 250px;


		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
		}
	}

	.nav-menu {
		@media (max-width:992px) {
			position: static;
		}

		@media (max-width:767px) {
			.dropdown-menu {
				transition: all 0.2s ease !important;
				transform: translate3d(0px, 73px, 0px) !important;
			}
		}


		&:hover {
			@media(min-width:993px) {
				.dropdown-menu {
					display: block;
				}
			}
		}
	}

	.header-main {

		@media (max-width:767px) {
			padding: 8px 0;
		}

		#logo {
			transition: all 0.3s ease;
			/* Füge eine Übergangsanimation hinzu */
		}

		.header-logo {
			position: absolute;
			z-index: 20;
			top: 13px;
			padding: 2px;
			background-color: transparent;
			border-radius: 4px;
			/*transition: all .5s ease;*/


			.logo {
				width: 110px;
				height: auto;
				/*	transition: all ease .2s;*/



			}

		}

		.main-menu {
			z-index: 5;
			top: 100%;
			padding: 0 !important;

			@media (min-width:768px) and (max-width:992px) {
				transform: translate3d(0px, 55px, 0px) !important;
			}

			@media (min-width:993px) {
				width: 640px;
				right: 0px;
				transform: inherit !important;
				top: 47px !important;
			}

			ul {
				padding: 0;
				margin: 0;
				background-color: #fff;

				@media (min-width:768px) {
					display: flex;
					flex-wrap: wrap;
				}
			}

			li {
				@media (min-width:768px) {
					width: 33.3%;
				}

				.dropdown-toggle {
					/*background-color: var(--main-nav-mob-menu-bg-color);*/
					font-weight: 700;
					border-bottom: 3px solid transparent;
					background-color: transparent;

					@media (min-width:993px) {
						padding: 0 0 10px;
						margin: 10px 15px;
						width: auto;
						display: inline-block;
					}

					&:hover {
						text-decoration: none;
						background-color: transparent;

					}

					&:focus {
						background-color: transparent;
					}

					&:after {
						@media (min-width:768px) {
							display: none;
						}
					}


				}

				.dropdown-menu {
					position: relative !important;
					transform: inherit !important;
					padding-bottom: 8px;

					@media (min-width:768px) {
						display: block;
						background-color: #fff;
						box-shadow: inherit;
						border-left: 1px solid #eee;
						height: 100%;
					}

					li {
						width: 100%;

						a {
							color: var(--main-nav-font-color);
							padding: 5px 15px !important;

							@media (max-width:767px) {
								font-weight: 400;
							}

							@media (min-width:768px) {
								font-size: 17px;
							}
						}
					}

					a {
						&:hover {
							text-decoration: none;
							color: var(--color-primary);
						}
					}
				}

				a,
				button {
					padding: 10px 15px;
					font-size: var(--main-nav-font-size);
					/*color: var(--main-nav-mob-font-color-hover);*/
					font-size: var(--main-nav-font-size);
					/*color: var(--color-white);*/
					border: none;
					display: block;
					width: 100%;
					text-align: left;

					@media (max-width:767px) {
						color: var(--main-nav-font-color);
						font-weight: 700;
					}

				}
			}
		}

		.search-menu {
			float: right;

			@media (max-width:992px) {
				display: none;

			}

			button {
				@media screen and (min-width: 768px) {
					color: var(--color-black) !important;
				}
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;

					@media (max-width:992px) {
						display: block;
						width: 100%;
					}

					.btn-round {
						margin-left: 15px;
						background-color: var(--btn-primary-bg-color);
						color: var(--btn-primary-font-color);
						border-color: var(--btn-primary-border-color);
						border-radius: var(--border-radius);
						padding: 12px 20px;
						text-decoration: none;
						font-size: 16px;
						margin-top: 4px;
					}

					&.dropdown {
						/*@media (min-width:993px) {
							&:hover {
								.dropdown-menu {
									visibility: visible;
									opacity: 1;
									pointer-events: auto;
									transform: inherit !important;
									top: 100% !important;
									display: block;
								}
							}

							.dropdown-menu {
								opacity: 0;
								display: none;
								pointer-events: none;
							} 

					}*/

						.dropdown-menu-right {

							@media (min-width:993px) {
								transform: inherit !important;
								right: 0;
								top: 100%;
								left: inherit;
							}

							&.show {
								top: 45px !important;
								right: 0 !important;
								left: inherit !important;
							}

						}

						.down-arrow {
							position: absolute;
							right: 6px;
							padding: 15px 9px 15px 0;
							top: 0;
							background-color: transparent;

							@media (max-width:992px) {
								padding-left: 137px;
								top: -4px;
								width: 100%;
								left: 0;
							}

							&:after {
								display: inline-block;
								margin-left: 0.255em;
								vertical-align: 0.255em;
								content: "";
								border-top: 0.3em solid;
								border-right: 0.3em solid transparent;
								border-bottom: 0;
								border-left: 0.3em solid transparent;
							}
						}

						.dropdown-toggle {
							border-bottom: 3px solid transparent;
						}

						&:hover {

							@media (min-width:993px) {
								background-color: transparent;
							}

							.dropdown-toggle,
							.drop-toggle,
							.down-arrow {


								text-decoration: none;

							}
						}

						.drop-toggle {
							padding-right: 34px;

							@media (max-width:992px) {
								&:after {
									display: inline-block;
									margin-left: 0.255em;
									vertical-align: 0.255em;
									content: "";
									border-top: 0.3em solid;
									border-right: 0.3em solid transparent;
									border-bottom: 0;
									border-left: 0.3em solid transparent;
								}
							}
						}


						.dropdown-toggle.show {
							border-bottom: 3px solid var(--color-secondary) !important;
							text-decoration: none;
						}
					}

					&:last-child {
						margin-right: 0;
					}


					>a,
					button {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 15px 0;
						line-height: 1;
						display: block;
						font-weight: 400;
						border: none;
						background: transparent;

						@media (max-width: 1024px) {
							font-size: 17px;
						}

						@media (max-width: 991px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:992px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}




					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}

	.dropdown-menu {
		overflow: hidden;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 200px;
		border-radius: 8px;

		@media (max-width: 992px) {
			border-radius: 0;
			width: 100% !important;
			/*max-height: 450px;
			overflow: scroll;*/
		}
	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;

			li {
				&.bold {
					font-weight: bold;
				}
			}

			@media (min-width: 993px) {
				display: flex;
				flex-wrap: wrap;

			}

			@media (max-width: 992px) {
				width: 100%;
			}


			li {
				@media (min-width: 993px) {
					width: 100%;
				}

				a {
					padding: 5px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 17px;

					&.bold {
						font-weight: bold;
					}

					@media (max-width:992px) {

						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;

					}
				}

			}
		}



	}

	.icon {

		position: relative;
		text-decoration: none;
		color: var(--color-secondary);
		font-size: 3;
		font-size: 26px;
		/*margin-left: 15px;*/
		padding: 15px 1.2vW;
		top: 5px;

		@media (max-width:340px) {
			font-size: 22px;
		}

		@media (max-width:767px) {
			position: absolute;
		}
	}

	.navactive {
		@media (min-width:993px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}

	.nav-row {


		.col-12 {
			display: flex;
			justify-content: flex-end;

			@media screen and (min-width: 768px) {
				position: relative;
			}

			.icons-nav {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				position: relative;

				@media screen and (max-width: 767px) {
					position: absolute;
					right: 45px;
					top: 20px;
				}

				@media (max-width:340px) {
					right: 40px;
					top: 18px;

				}

				li {
					&.btn-call {
						cursor: pointer;

						@media(max-width:992px) {
							padding: 0 10px;
						}

						a {
							&:hover {
								color: var(--color-grey-dark);
							}
						}
					}

					a {
						padding: 15px 1.2vW;
						font-size: var(--main-nav-font-size);

						@media (max-width:340px) {
							padding: 16px 2px;
							font-size: 14px;
						}
					}
				}
			}
		}
	}


	@media screen and (max-width: 767px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
			top: 12px !important;
		}

		.nav-row {
			min-height: 50px !important;
		}

		.icon {
			display: block;
			bottom: 10px;
		}




		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 0px !important;
			z-index: 50;
			top: -8px !important;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}
	}

	@media screen and (max-width: 767px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {
			.nav-row {
				min-height: 20px !important;
			}

			.icon {
				position: absolute;
				top: 8px;
				right: 8px;
				bottom: initial;
				font-size: 22px;
				line-height: 1;
			}
		}

	}


}



.to-top {
	position: fixed;
	cursor: pointer;
	bottom: 5px;
	display: none;
	right: 10px;
	width: 32px;
	height: 32px;
	border-radius: var(--border-radius);
	background-color: var(--color-secondary);
	color: var(--color-white);
	z-index: 200;
	line-height: 32px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

}