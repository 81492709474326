.search-unit-v1 {

	.unit-item {
		background-color: #fff;
		/*border: 1px solid var(--color-grey-normal);
		border: 1px solid var(--color-secondary);
		border: 1px solid #e5e4e4;*/
		border: 1px solid var(--color-border);
		transition: all 0.2s;
		height: 100%;
		border-radius: var(--border-radius);

		.alternatives {
			text-align: center;
			background-color: var(--color-grey-light);
			padding: 0 10px;

			.li-bullet {
				display: flex;
				flex-wrap: nowrap;
				justify-content: center;
				gap: 10px;
				text-align: center;

				li {
					width: 49%;
				}
			}

			.altheading {
				font-weight: 600;
				padding: 10px 0px;
				font-size: 15px;

				&.alt {
					color: var(--color-black);
				}

				&.vor {
					color: var(--color-green);
				}

			}

			a {
				font-size: var(--font-size-md);
				text-decoration: none;

				&:hover {
					text-decoration: none;
				}
			}

			.alternate-time {
				border: 1px solid var(--color-grey-normal);
				padding: 3px;
				margin-bottom: 5px;
				border-radius: var(--border-radius);
				color: var(--font-color-main);
				background-color: var(--color-white);
				font-size: 14px;

				&:hover {
					background-color: var(--color-grey-dark);
					color: var(--color-white);
				}

				.total-price {
					font-weight: 700;
					font-size: 20px;
					color: var(--link-font-color);
					margin: 0 5px;
				}

				.original-price {
					text-decoration: line-through;
					font-weight: 400;
				}

				.period {
					font-weight: 600;
				}

				.special {
					font-weight: 600;
				}

				.fa-sharp {
					font-size: var(--font-size-sm);
					float: right;
					padding-top: 5px;
				}
			}
		}

		&:hover {
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
			transform: scale(1.015);

			.go-to-unit {
				opacity: 1 !important;
			}
		}

		.price {
			padding: 0 10px;
			background-color: var(--color-grey-light);

			.available {
				text-align: center;
				font-weight: 600;
				padding-top: 10px;
				color: var(--color-green);
				font-size: 15px;

				/*.time-period {
						font-size: 14px;
						color: var(--font-color-main);
						font-weight: 400;
					} */

			}

			.rent-rate {
				display: flex;
				flex-direction: row;
				justify-content: center;

				.price-details {
					border: 1px solid var(--color-grey-normal);
					padding: 5px;
					margin: 10px 0px;
					border-radius: 5px;
					border-radius: var(--border-radius);
					color: #333;
					color: var(--font-color-main);
					text-align: center;
					width: 99%;
					font-size: 14px;
					cursor: pointer;

					&:hover {
						text-decoration: none;

						.original-price {
							text-decoration: line-through;
						}

						div,
						strong,
						.total-price,
						a,
						span {

							text-decoration: none;

						}
					}

					.period {
						font-weight: 600;
					}

					.total-price {
						font-weight: 700;
						font-size: 20px;
						color: var(--link-font-color);
						margin: 0 5px;
					}

					.original-price {
						text-decoration: line-through;
					}


				}

				.details {
					margin: 10px 0px;
				}
			}
		}

		.unit-container {
			display: flex;
			flex-direction: column;
			height: 100%;
			position: relative;

			.close-map-btn {
				position: absolute;
				right: 5px;
				top: 5px;
				padding: 0px 10px;
				font-size: 26px;
				line-height: 28px;
				z-index: 3;
				color: var(--color-black);
				background-color: var(--color-white);
				cursor: pointer;
				border-radius: 5px;
				border-radius: var(--border-radius);
				border: 1px solid var(--color-rey-dark);

			}

			.unit-link {
				color: var(--font-color-main);
				text-decoration: none;

				&:hover {
					color: var(--font-color-main);
					text-decoration: none;
				}
			}

			.unit-map {
				display: none;
				height: 273px;
				overflow: hidden;

				@media(max-width:1499px) {
					height: 262px;
				}

				@media(max-width:1400px) {
					height: 224px;
				}


				@media(max-width:1199px) {
					height: 237px;
				}

				@media(max-width:991px) {
					height: 357px;
				}

				@media(max-width:767px) {
					height: 357px;
				}

			}

			.searchMap {
				height: 273px;

				@media(max-width:1499px) {
					height: 262px;
				}

				@media(max-width:1400px) {
					height: 224px;
				}


				@media(max-width:1199px) {
					height: 237px;
				}

				@media(max-width:991px) {
					height: 357px;
				}

				@media(max-width:767px) {
					height: 357px;
				}
			}

			.img-col {
				position: relative;
				width: 100%;
				padding-top: 56.25%;

				.fav-icon {
					position: absolute;
					top: 3px;
					right: 4px;
					color: var(--color-primary);
					cursor: pointer;
					z-index: 2;
					font-size: 18px;
					padding: 5px 6px 4px;
					border-radius: 5px;
					background-color: hsla(0, 0%, 100%, .8);
					border: 1px solid var(--color-grey-light);
					line-height: 1;
				}

				.popup-buttons {
					position: absolute;
					left: 5px;
					top: 5px;
					margin-right: 5px;
					display: flex;
					gap: 6px;
					align-items: center;

					.button {
						padding: 0 5px;
						line-height: 28px;
						min-width: 75px;
						text-align: center;
						z-index: 2;
						color: var(--font-color-main);
						background-color: rgba(255, 255, 255, 0.8);
						font-size: var(--font-size-md);
						cursor: pointer;
						border-radius: var(--border-radius);
						display: inline-block;
						border: 1px solid var(--color-grey-light);

						&:hover {
							color: var(--color-white);
							background-color: rgba(55, 55, 55, 0.8);
						}

					}
				}


				.rating {
					position: absolute;
					right: 3px;
					bottom: 3px;
					z-index: 2;
					font-size: var(--font-size-md);

					.avg {
						text-align: center;
					}

					.avg-symbol {
						color: var(--font-color-main);
						background-color: hsla(0, 0%, 100%, .8);
						/*background-color: var(--bg-color-feedback);
						color: var(--font-color-feedback);*/
						padding: 3px;
						border-radius: var(--border-radius);
						font-weight: normal;
						font-size: 16px;

						i {
							color: var(--color-primary);
						}
					}

					.count {
						font-size: 12px;
						background: rgba(255, 255, 255, 0.8);
						padding: 3px;
						border-radius: 5px;
						margin-top: 2px;
						display: inline-block;
					}

				}

				/*.price {
					position: absolute;
					background-color: rgba(255, 255, 255, 1);
					border-radius: var(--border-radius);
					text-align: center;
					top: 45px;
					right: 10px;
					padding: 0px 10px;
					z-index: 2;
					color: var(--font-color-main);

					.h3 {
						font-size: 18px;
						color: var(--font-color-main);
						line-height: 28px;
					}

					.main-price {
						&.h5 {
							color: var(--color-grey-dark);
							text-decoration: line-through;

						}
					}

					.reducedprice {
						color: #e67800;
					}


				}*/

				img {
					width: 100%;
					object-fit: cover;
					height: 100%;
					object-position: center center;
				}

				.img-content {
					.title-stars {
						position: absolute;
						left: 3px;
						bottom: 3px;
						background-color: #fff;
						border-radius: 5px;
						padding: 3px 5px 0px;
					}

					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					overflow: hidden;
					border-top-left-radius: var(--border-radius);
					border-top-right-radius: var(--border-radius);

				}

			}

			.unit-facts {
				padding: 0px 10px 10px;
				/*font-weight: bold;*/

				.unit-title {
					padding-top: 10px;
					/*height: 57px;*/

					overflow: hidden;
					display: flex;
					flex-wrap: wrap;

					.h3 {
						font-size: var(--font-size-main);
					}

					.title-stars {
						position: relative;
						right: -5px;
						margin-top: 2px;
						display: flex;
						align-items: center;
					}


				}

				.type {
					font-size: var(--font-size-md);
				}

				.place {
					font-size: var(--font-size-md);
					min-height: 40px;

					a {
						color: var(--color-primary);
					}

					.fa {
						color: var(--color-primary);
					}
				}

				.fa-icon {
					position: relative;
					top: 2px;
				}

				.unit-highlights {
					.fa-icon {
						margin-left: 0px;
					}

					.fa,
					.fas,
					.far,
					.fa-regular,
					.fal {
						padding-right: 5px;
					}


					.fa-stack {
						height: 33px !important;
						margin-left: -5px;
						width: 24px !important;
					}
				}

				.flex-middle {
					flex-grow: 1;
					padding: 10px 0px;
				}

				.flex-bottom {
					padding-bottom: 10px;
					display: none;
				}

				.properties-row {
					min-height: 80px;
					display: flex;

					.unit-properties {
						width: 50%;
						font-size: var(--font-size-md);
					}
				}



			}

			.go-to-unit {
				height: 30px;
				display: inline-block;
				width: 30px;
				line-height: 30px;
				text-align: center;
				border-radius: 50%;
				background-color: var(--color-primary);
				transition: all 0.5s;
				opacity: 0;

				.fa {
					color: var(--color-white);
					font-size: 20px;
					line-height: 30px;
				}

			}

			.offer-box {
				position: absolute;
				right: 44px;
				top: 2px;
				z-index: 5;

				.offer-img {
					width: 30px !important;
				}

			}

		}
	}

}

.popover {
	border-radius: 0;
	background-color: var(--color-white);
	border: 1px solid #C0C0C0;
	font-size: var(--font-size-md);
	box-shadow: inherit;
	width: 100%;
	max-width: 290px;
	/*max-width: 520px;*/

	.popover-header {
		border-radius: 0;
		background-color: var(--color-grey-light);
		margin-bottom: 10px;
		padding: 10px 10px;
		font-size: var(--font-size-md);
		font-weight: normal;
	}

	.arrow {
		&:after {
			border-top-color: #C0C0C0 !important;
		}
	}

	.popover-body {
		padding: 10px;
		font-size: 0.80rem;


		ul {
			overflow-y: scroll;
			height: 180px;
			padding-right: 10px;


			li {
				position: relative;
				margin-bottom: 2px;
				padding-left: 14px;
				list-style: none;
				font-size: var(--font-size-md);
				font-family: var(--font-family-main);
				margin-bottom: 3px;

				&:before {
					background-color: var(--color-primary);
					width: 5px;
					height: 5px;
					position: absolute;
					content: "";
					left: 0;
					top: 6px;
					border-radius: 50%;

				}
			}
		}
	}
}