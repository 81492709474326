.unit-sub-title-v1 {
	padding-bottom: 20px;

	@media(min-width:768px) {
		display: flex;
		justify-content: space-between;
	}

	.title {
		max-width: 85%;
	}

	h1 {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	.unit-subtitle {
		font-size: var(--font-size-md);
		padding-top: 10px;

		.fa,
		.fas,
		.far {
			width: 20px;
			color: var(--color-primary);
		}

		.type {
			color: var(--color-grey-dark);
		}

		.address {
			color: var(--color-grey-dark);


		}
	}

	.avg {
		min-width: 92px;
		cursor: pointer;
		display: flex;
		flex-direction: column;

		@media(min-width:768px) {
			text-align: center;
		}

		@media(max-width:767px) {
			padding-top: 10px;
		}

		.avg-count {

			font-size: 14px;

		}
	}

	.avg-symbol {
		display: inline-block;
		color: var(--font-color-main);
		font-weight: bold;
		font-size: 24px;

		i {
			color: var(--color-primary)
		}
	}

}