.regionen-v1 {
	margin-top: var(--page-margin-top);

	.desktop-map {
		height: 450px;
	}

	.inner-banner {
		&.region-10109 {
			background-image: url(RESOURCE/img/banner-roebel.webp);
		}

		&.region-10107 {
			background-image: url(RESOURCE/img/banner-malchow.webp);
		}

		&.region-10103 {
			background-image: url(RESOURCE/img/banner-krakow.webp);
		}

		&.region-10102 {
			background-image: url(RESOURCE/img/banner-waren.webp);
		}

		&.region-10169 {
			background-image: url(RESOURCE/img/banner-wustrow.webp);
		}

		&.region-10101 {
			background-image: url(RESOURCE/img/banner-zingst.webp);
		}

		&.region-10108,
		&.region-10106,
		&.region-10168 {
			background-image: url(RESOURCE/img/banner-ostsee1.webp);
		}

		&.region-10105,
		&.region-10118,
		&.region-10119,
		&.region-10104,
		&.region-10121,
		&.region-10116,
		&.region-10115,
		&.region-10114,
		&.region-10113,
		&.region-10112,
		&.region-10111,
		&.region-10085 {
			background-image: url(RESOURCE/img/banner-standard-region-mueritz.webp);
		}
	}

	.reg-div {
		min-height: 510px;
	}
}