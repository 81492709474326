.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);
	padding-top: 30px;

	.unit-title-v1 {
		position: relative;
	}

	.share {
		font-size: var(--font-size-md);
		color: var(--color-primary);
		cursor: pointer;

		.fa-share-alt:before {
			content: "";
			background-image: url(RESOURCE/img/icon-share-nodes.svg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: 16px;
			width: 16px;
			display: inline-block;
			position: relative;
			top: 2px;
			filter: brightness(0) saturate(100%) invert(52%) sepia(75%) saturate(2998%) hue-rotate(8deg) brightness(97%) contrast(101%);
		}

		@media(min-width:993px) {
			position: absolute;
			right: 15px;
			top: 9px;
		}

		@media(max-width:992px) {
			margin-top: -15px;
		}

		&:hover {
			color: var(--color-secondary);
		}
	}

	.breadcrumb {
		@media (max-width:1200px) {
			padding-top: 20px !important;
		}

		@media (max-width:767px) {
			padding-top: 5px !important;
		}
	}

	.unit-stikcy-bar-mob {
		display: none;

		@media (max-width:767px) {
			display: block;
			position: fixed;
			bottom: 0;
			z-index: 5;
			width: 100%;
			background-color: var(--color-white);
			display: flex;
			justify-content: space-between;
			padding: 8px 20px;
			align-items: center;
			box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
		}

		.btn-primary {
			min-width: 120px;
		}

		.avg-symbol {

			font-weight: 400;
			font-size: 16px;
		}

		.calcprice {
			font-weight: 700;
		}

		.seldates {
			text-decoration: underline;

			a {
				color: black !important;
			}
		}
	}

	.voucher {
		background: var(--color-white);
		padding: 10px;
		margin-top: 10px;
		border-radius: var(--border-radius);

		.title {
			font-weight: 600;
			font-size: 20px;
			padding-bottom: 10px;
		}

		.form-control {
			margin-bottom: 8px;
		}


		.text {}

		.voucher-close {
			float: right;
			font-size: 1.5rem;
			font-weight: 700;
			line-height: .8;
			color: #000;
			text-shadow: 0 1px 0 #fff;
			opacity: .5;
			border: none;
			background: transparent;
		}
	}

	.title-stars {
		color: #e0880c;
		font-size: 0.65em;

		top: -0.7em;
		display: inline-block;

		@media (max-width: 767px) {
			top: 0em;
		}
	}


	.section-mb {

		@media (min-width: 768px) {
			margin-bottom: 1rem !important;
		}

		@media (max-width: 767px) {
			margin-bottom: 0.25rem !important;
		}

	}


	.section {

		padding: 0px;

		.d-flex.justify-content-between {
			.avg-symbol {
				font-weight: normal;
				font-size: 24px;
			}
		}

		.avg-symbol {
			font-weight: normal;
			font-size: 20px;

			i {
				color: var(--color-primary);
			}
		}


		@media (min-width: 768px) {
			position: relative;
			border: 1px solid var(--color-border);
			border-radius: var(--border-radius);
			padding-bottom: 2px;
			scroll-margin: var(--scroll-margin);
		}

		.section-title {

			cursor: pointer;
			position: relative;

			padding-left: 20px;
			margin-top: 10px;

			font-size: var(--h3-font-size);
			font-family: var(--font-family-main);


			h2 {
				font-size: var(--h3-font-size);
				font-family: var(--font-family-main);
			}


			@media (max-width: 767px) {
				text-align: center;
			}

			.fa-chevron-down {
				display: none;
				position: absolute;
				right: 20px;
				top: 5px;
			}

			.fa-chevron-right {
				display: block;
				position: absolute;
				right: 20px;
				top: 5px;
			}

			&.show {
				.fa-chevron-right {
					display: none;
				}

				.fa-chevron-down {
					display: block;
				}
			}

		}

		.section-btn {
			@media (min-width: 768px) {
				display: none;
			}

			@media (max-width: 767px) {

				width: 100%;
				height: 40px;
				line-height: 40px;
				font-size: var(--mobile-section-btn-font-size);
				padding: 0px 10px;
				cursor: pointer;
				border: 1px solid var(--mobile-section-btn-border-color);
				border-radius: var(--border-radius);
				position: relative;
				margin-bottom: 10px;
				background-color: var(--mobile-section-btn-bg-color);

				.section-btn-title {
					position: absolute;
					margin-left: 30px;
					top: 0;
					color: var(--mobile-section-btn-font-color);
				}

				.fa,
				.far,
				.fas,
				.fa-light {
					padding-right: 5px;
					color: var(--mobile-section-btn-font-color);
				}

				.fa-chevron-right {
					position: absolute;
					right: 5px;
					top: 14px;
					font-size: 12px;
				}

			}
		}



	}

	.showmore {

		.showmorebutton {
			@media (max-width: 767px) {
				display: none;

			}

			position: absolute;
			bottom: 10px;
			margin: auto;
			left: 0;
			right: 0;
			width: 140px;
			background-color: var(--btn-default-bg-color);
			color: var(--btn-default-font-color);
			border:1px solid var(--btn-default-border-color);
			cursor: pointer;
			border-radius: var(--border-radius);
			text-align: center;
			font-size: var(--font-size-md);
			min-width: 190px;
			z-index: 2;
			line-height: 25px;

			&:hover {
				background-color: var(--btn-default-bg-color-hover);
				border-color: var(--btn-default-border-color-hover);
				color: var(--btn-default-font-color-hover);
			}
		}

		@media (max-width: 767px) {
			width: 100%;
			bottom: 0;
			top: 0;
			position: fixed;
			padding: 0px;
			background-color: #fff;
			transition: all 0.8s ease 0s;
			z-index: 9999;
			right: -102%;
			height: 100vh;
			box-shadow: -3px 0 4px -4px rgba(124, 125, 126, 0.8);


			&.show {
				right: 0px;
				top: 0px;
			}

			.header {
				width: 100%;
				height: 50px;
				right: 0;
				top: 0;
				position: absolute;
				z-index: 1;
				cursor: pointer;
				color: var(--mobile-sidebar-header-font-color);
				border-bottom: 1px solid var(--mobile-sidebar-header-border-color);
				background-color: var(--mobile-sidebar-header-bg-color);

				.fa-xmark {
					position: absolute;
					top: 16px;
					right: 15px;
					font-size: 18px;
				}
			}

			.title {
				width: 100%;
				height: 50px;
				position: fixed;
				display: block;
				margin-bottom: 0;
				font-size: var(--mobile-sidebar-header-font-size);
				line-height: 50px;
				top: 0;
				z-index: 2;
				padding-left: 20px;
				pointer-events: none;
				color: var(--mobile-sidebar-header-font-color);

				.far,
				.fa,
				.fas,
				.fa-light {
					color: var(--mobile-sidebar-header-font-color);
					padding-right: 10px;
				}
			}

			.content {
				padding: 20px 20px;
				height: calc(100vh - 50px);
				overflow-y: auto;
				position: absolute;
				z-index: 99999;
				top: 50px;
				max-width: 100%;
				padding-bottom: 20px;
				width: 100%;

			}
		}

		@media (min-width: 768px) {
			position: relative;
			max-height: 0px;
			overflow: hidden;

			&.show {
				overflow: visible;
				max-height: inherit;
				padding: 40px 20px 20px;
			}

			.header {
				display: none;
			}

			.title {
				display: none;
			}


		}
	}


	.desktop-map {
		height: 450px;
	}

	.sticky {
		position: -webkit-sticky;
		position: sticky;
		top: var(--page-margin-top);
		z-index: 3;

	}

	.unitnav {
		width: 100%;
		z-index: 5;

		.container {
			display: flex;
			justify-content: space-between;
			background: #fff;
			padding-bottom: 20px;


			div {
				margin-top: 3px;
				padding: 5px 2px;
				text-align: center;
				background: #fff;
				border: 1px solid var(--color-grey-dark);
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				cursor: pointer;
				margin-right: 5px;
				font-size: 0.9rem;
				border-radius: var(--border-radius);

				@media(max-width:768px) {
					font-size: var(--font-size-md);
				}

				&:last-child {
					margin-right: 0;
				}


				&.book {
					background-color: red;
					color: #fff;
					margin-right: 0px;

					&:hover {
						background-color: green;
					}
				}

				&:hover {
					background-color: var(--color-grey-light);
				}

				&.active {
					background-color: var(--color-secondary);
					color: var(--color-white);
				}
			}

		}
	}

	.price-section {
		position: sticky;
		top: 125px;
		min-height: 200px;
		margin-left: 30px;
		border-radius: var(--border-radius);
		margin-bottom: 1rem !important;
	}

	.booking-section {
		border: 1px solid var(--color-border);
		background-color: var(--color-grey-light);
		border-radius: var(--border-radius);
	}

	.price-info {
		background-color: #fff;
		border-radius: var(--border-radius);
		padding: 15px;
		border: 1px solid var(--color-border);
		margin-bottom: 15px;

		.price {
			font-size: 24px;
			text-align: center;
			font-weight: 700;
			color: var(--color-primary);
		}

		.original-price {
			text-align: center;
			font-weight: 400;
			font-size: 18px;
			text-decoration: line-through;
		}

		.date {
			font-size: var(--font-size-md);
			text-align: center;
		}

		.no-price {
			text-align: center;
		}

		.button-line {
			padding-top: 10px;
		}
	}

	.header-buttons {
		position: absolute;
		top: 10px;
		left: 20px;
		z-index: 3;

		@media (max-width:767px) {
			top: 5px;
			left: 15px;
			z-index: 3;
		}

		@media (min-width:577px) {

			.fa,
			.fa-light,
			.far,
			.fas {
				padding-right: 5px;
			}
		}
	}

	.video-container {
		position: relative;
		height: 0;
		padding-bottom: 56.25%;
		/* 16:9 Format */

		iframe {
			position: absolute;
			top: 40px;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.video-hint {
		position: relative;
		background-color: #000;
		aspect-ratio: 16/9;
		padding: 20px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;

		@media(max-width:500px) {
			padding: 5px;
			font-size: 13px;
		}

		.text {
			text-align: center;
		}

		.button-line {
			text-align: center;
			margin: 10px 0px;

			@media(max-width:500px) {
				margin: 0px 0px;
			}
		}
	}

}

.vo-unit-body {
	.to-top {
		@media (max-width:767px) {
			bottom: 100px;
			right: 20px;
		}
	}
}