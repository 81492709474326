.unit-feedbacks-v1 {
	.avg-generell {
		padding-bottom: 20px;

		.avg-count {
			font-weight: 700;
			font-size: 18px;
		}
	}

	#moreFeedbacksBtn:active,
	#moreFeedbacksBtn:focus {
		background-color: var(--btn-primary-bg-color);
		border-color: var(--btn-primary-border-color);
	}

	#moreFeedbacksBtn:hover {
		background-color: var(--btn-primary-bg-color-hover);
		border-color: var(--btn-primary-border-color-hover);
	}


	.fb-kat {
		padding: 10px 0;
	}

	.avg-symbol {
		display: inline-block;
		background-color: transparent;
		color: var(--font-color-main);
		padding: 0;
		font-size: 28px;
	}




	.stars {
		color: var(--color-primary);
	}

	.avg-details {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-bottom: 20px;

		.criteria {

			padding: 10px 0;

			border-bottom: 1px solid var(--color-border);

			&:last-child {
				border-bottom: none;
			}

			.fb-content {

				display: flex;
				flex-direction: row;
				justify-content: space-between;
				position: relative;
				padding-left: 40px;

				i {
					position: absolute;
					left: 0;
					width: 28px;
					height: 28px;
					display: inline-block;
					background-position: center;
					background-repeat: no-repeat;
					top: -5px;

					&.icon-ausstattung {
						background-image: url(RESOURCE/img/icon-ausstattung.svg);
					}

					&.icon-price {
						background-image: url(RESOURCE/img/icon-preis-leistung.svg);
					}

					&.icon-lage {
						background-image: url(RESOURCE/img/icon-lage.svg);
					}

					&.icon-sauberkeit {
						background-image: url(RESOURCE/img/icon-sauberkeit.svg);
					}

					&.icon-weiter {
						background-image: url(RESOURCE/img/icon-weiterempfehlung.svg);
					}

				}

				.name {
					font-size: var(--font-size-md);
				}

				.avg-symbol {
					font-size: 14px;
					min-width: 35px;
				}
			}
		}
	}

	.fb-entries {
		.guest-avg {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.avg-symbol {
				font-size: var(--font-size-normal);
				margin-bottom: 16px;
				text-align: center;
				max-width: 90px;
			}

			.criteria {
				font-size: var(--font-size-md);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-bottom: 2px;

				.avg-symbol {
					margin-bottom: 0px;
					/*	max-width: 60px;
					min-width: 60px;*/
				}

				.name {}
			}
		}

		.fb-entry {
			margin-bottom: 30px;
			padding-top: 30px;


			.comment {
				position: relative;

				.avg-symbol {
					font-size: var(--font-size-md);

					@media (min-width: 768px) {
						display: none;
					}
				}

				.guest-info {
					margin-bottom: 10px;

					span {
						font-size: var(--font-size-sm);
						padding-right: 5px;
						position: relative;
						padding-left: 28px;
						margin-right: 15px;

						i {
							margin-right: 5px;
							width: 22px;
							height: 20px;
							display: inline-block;
							background-position: center;
							background-repeat: no-repeat;
							position: absolute;
							top: -3px;
							left: 0;

							&.icon-user {
								background-image: url(RESOURCE/img/icon-person.svg);
							}

							&.icon-calendar {
								background-image: url(RESOURCE/img/icon-kalender.svg);
							}
						}


					}
				}

				.guest-text {
					font-size: var(--font-size-md);

					margin-bottom: 10px;
					border-radius: var(--border-radius);
				}

				.agency-comment {
					font-size: var(--font-size-md);
					padding: 8px;
					background-color: var(--color-grey-light);
					margin-left: 15px;
					border-radius: 5px;

					&:before {
						width: 0;
						height: 0;
						border-top: 15px solid transparent;
						border-bottom: 15px solid transparent;
						border-right: 15px solid #f5f5f5;
						content: "";
						position: absolute;
						left: 15px;
					}

					strong {
						margin-bottom: 15px;
						display: block;
					}
				}
			}
		}
	}

	.button-line {
		width: 100%;
		margin-top: 30px;
		margin-bottom: 30px;
	}

}