.contact-form-v1 {
	border-radius: var(--border-radius);
	/*border: 1px solid var(--color-border);*/
	background-color: var(--color-grey-light);

	.checkbox label {
		vertical-align: inherit;
	}

	.grund-der-option {
		display: block;
		/*width: 100%;*/
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #212529;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		-webkit-appearance: none;
		-moz-appearance: none;
		/* appearance: none;*/
		border-radius: 0.375rem;
		-webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
		transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

		&:focus {
			box-shadow: none;
			color: #212529;
			background-color: #fff;
			border-color: #86b7fe;
			outline: 0;
		}
	}

	.red {
		color: var(--color-red);
	}
}