#lastviewed-units {
	border-top: 1px solid #cecece;
}

.footer-v1 {
	padding-top: 80px;
	background-color: var(--color-white);

	.list-unstyled {
		li {
			a {
				position: relative;
				padding-left: 15px;

				&:before {
					position: absolute;
					left: 0;
					top: 0px;
					content: "";
					font-weight: 300;
					font-family: "Font Awesome 6 Pro";
				}
			}
		}
	}

	.main-footer {
		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.sub-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			position: relative;

			@media (max-width:992px) {
				flex-direction: column;
				align-items: baseline;
				gap: 1.5rem;

			}

			.social-icons {
				position: relative;
				top: 2px;

				@media (min-width:993px) {
					position: absolute;
					top: 8px;
					top: 7px;
					left: 50%;
					transform: translate(-50%, 0);
				}

			}

			.sub-ft-link {
				li {
					border-right: 1px solid #fff;
					padding-right: 10px;
					margin-right: 10px;
					line-height: 1;
					margin-bottom: 3px;
					margin-top: 3px;

					&:last-child {
						border-right: none;
					}
				}


			}

			ul {
				margin: 0;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;

				@media (max-width:992px) {
					flex-wrap: wrap;
					justify-content: flex-start;
				}
			}
		}

		.newsletter-post-bl {
			width: 100%;
			padding: 0 20px;
			margin-top: -70px;

			@media (max-width:767px) {
				padding: 0 15px;
			}

			.container {
				padding: 0 20px;

				@media (max-width:767px) {
					padding: 0;
				}
			}

			.contact-form {
				margin-bottom: 0;

				p:last-child {
					margin-bottom: 0;
				}

				p,
				.heading1 {
					color: var(--secondary);
				}
			}
		}

		.footer-cont {
			@media (max-width:992px) {
				padding-bottom: 0;
			}
		}

		.newslet-bl {
			/*background-color: var(--color-secondary);*/
			background-color: #1d4b7d;
			padding: 15px;
			border-radius: 10px;
			max-width: 1210px;
			margin: 0 auto;
			text-align: center;

			.fa-info-btn {
				cursor: pointer;

				.fa-info-circle {
					color: var(--color-white);
					padding-left: 5px;
					position: relative;
					top: -4px;
					font-size: 14px
				}
			}

			p {
				color: #fff;
				margin-bottom: 8px;

				/*	@media (max-width:767px) {
					font-size: 12px;
				}*/
			}

			@media (max-width:992px) {
				padding: 15px;
			}

			.newsletter-text {
				color: var(--white);
				margin-bottom: 25px;

				.h3 {
					color: var(--white);
					font-size: 18px;
					font-weight: bold;
					font-family: var(--h4-font-family);
				}

				.sub-text {
					span {
						padding: 0 10px;

						@media(max-width:767px) {
							display: block;
						}
					}
				}

			}

			h2 {
				color: var(--white);
				text-transform: uppercase;
				margin-bottom: 40px;
				font-size: 21px;

				@media (max-width:992px) {
					margin-bottom: 30px;
				}
			}

			.widgt-grey-bl {

				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;

				@media (max-width:767px) {
					flex-direction: column;
				}

				&.newslatter-link {
					position: relative;
					padding-bottom: 39px;

					@media(max-width:992px) {
						padding-bottom: 30px;
					}

					@media(max-width:767px) {
						padding-bottom: 0px;
					}
				}

				.checkbox-bl {
					position: absolute;
					bottom: 0;

					@media(max-width:768px) {
						position: relative;
						margin-bottom: 10px;
						font-size: 16px;
					}

					@media(max-width:767px) {
						.checkbox label:before {
							top: 4px;
							width: 14px;
							height: 14px;
							left: 7px;
						}
					}

					@media(max-width:767px) {
						.checkbox label:after {
							left: 5px;
						}
					}
				}

				.widt-cont-bl {

					color: var(--color-white);
					border-radius: 0.375rem;

					.form-control {
						border: none;
						background-color: var(--color-white);
						font-size: 16px;
						font-family: var(--d-font);
						height: inherit;
						padding: 0.375rem 0.75rem;
						width: 300px;
						margin-right: 15px;
						border: 1px solid #4e9fd0;

						@media (max-width:550px) {
							font-size: 13px;
							width: 220px;
							/*margin-bottom: 8px;*/
							margin-right: 0;
						}
					}
				}

				.btn-primary {
					padding: 0.375rem 0.75rem;
					border-radius: 0.375rem;

					@media (max-width:992px) {
						font-size: 16px;
					}
				}
			}
		}

		.text-muted {
			color: var(--color-grey-normal) !important;
		}


		position: relative;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}

		.contact-bl {
			i {
				color: #fff;
				padding-right: 5px;
			}

			li {
				padding-bottom: 5px;
			}

		}

		.footer-logo {
			@media (max-width:767px) {
				display: none;
			}

			a {
				background-color: #fff;
				padding: 10px;
				width: 180px;
				height: auto;
				margin-bottom: 20px;
				border-radius: 8px;
				display: inline-block;

				.logo {
					width: 100%;
					height: 100%;
				}
			}
		}



		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);

					&.fa-icon-apple {
						position: relative;
						top: -4px;
					}

					&.fa-icon-spotify {
						position: relative;
						top: -2px;
					}

					.fa,
					.fa-brands {
						font-size: 20px;
						color: var(--footer-font-color);


					}


				}
			}

		}




		#button-addon1 {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa,
			.fa-brands {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa,
			.fa-brands {
				color: var(--color-white);
				font-size: 1.8rem;
				padding-left: 10px;

			}
		}

		.container-xl {
			padding: 0 20px;
		}
	}

	/*.v-office-logo {
		background-color: var(--color-secondary);
		padding: 10px 20px;

		@media(min-width: 993px) {
			text-align: right;
			padding: 10px 60px;
		}

		@media(max-width: 992px) {
			text-align: left;
		}
	}*/

}