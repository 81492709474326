/*@font-face {
	font-family: 'SourceSansPro';
	font-style: normal;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/SourceSansPro-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'SourceSansPro';
	font-style: Italic;
	font-weight: 300;
	src: local(''),
		url('RESOURCE/fonts/SourceSansPro-LightIt.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-LightIt.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'SourceSansPro';
	font-style: normal;
	font-weight: 400;
	src: local(''),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff') format('woff');
	font-display: swap;
}

@font-face {
	font-family: 'SourceSansPro';
	font-style: bold;
	font-weight: 600;
	src: local(''),
		url('RESOURCE/fonts/SourceSansPro-Semibold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Semibold.woff') format('woff');
	font-display: swap;
}
*/


/*@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.eot");
	src: url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.woff2") format("woff2"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.woff") format("woff"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.ttf") format("truetype"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-regular-400.svg#fontawesome") format("svg");
}

@font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.eot");
	src: url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.woff2") format("woff2"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.woff") format("woff"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.ttf") format("truetype"),
		url("https://r.v-office.com/webfonts/fontawesome-pro/fa-brands-400.svg#fontawesome") format("svg");
}*/

/* source-sans-3-300 - latin */
@font-face {
	font-display: swap;
	font-family: 'Source Sans 3';
	font-style: normal;
	font-weight: 300;
	src: url('RESOURCE/fonts/source-sans-3-v15-latin-300.woff2') format('woff2');
}

/* source-sans-3-300italic - latin */
@font-face {
	font-display: swap;
	font-family: 'Source Sans 3';
	font-style: italic;
	font-weight: 300;
	src: url('RESOURCE/fonts/source-sans-3-v15-latin-300italic.woff2') format('woff2');
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-regular - latin */
@font-face {
	font-display: swap;
	font-family: 'Source Sans 3';
	font-style: normal;
	font-weight: 400;
	src: url('RESOURCE/fonts/source-sans-3-v15-latin-regular.woff2') format('woff2');
}

/* source-sans-3-600 - latin */
@font-face {
	font-display: swap;
	font-family: 'Source Sans 3';
	font-style: normal;
	font-weight: 600;
	src: url('RESOURCE/fonts/source-sans-3-v15-latin-600.woff2') format('woff2');
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}