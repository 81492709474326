.unit-calendar {
	scroll-margin: var(--scroll-margin);

	.calInfo {
		margin-right: 20px;
		margin-left: 20px;
		font-size: .875rem;
		font-size: var(--font-size-md);
		height: 70px;
		text-align: center;
		padding: 5px;
		color: #5d7a83;

		.date {
			font-weight: 600;
		}

		.fa-circle-info {
			padding-right: 5px;
		}
	}

	.datepicker-trigger {
		display: inline-block;
		border: 1px solid var(--color-border);
		border-radius: var(--border-radius);

		.asd__wrapper {
			border: none;
			border-radius: var(--border-radius);
			background-color: none;
		}

		.asd__day--disabled,
		.asd__day--empty {
			opacity: 1.0 !important;
			cursor: not-allowed;
			background: transparent !important;
			border: none;
		}

		.asd__day-button {
			pointer-events: auto;
		}

		.asd__day-button:disabled,
		.asd__day-button[disabled] {
			pointer-events: none;
			text-decoration: line-through;
			color: var(--cal-text-color) !important;
			opacity: .5 !important;
		}

		.asd__day--scope {
			background: transparent !important;
			color: var(--cal-text-color) !important;
			text-decoration: line-through;
			opacity: 0.8;

			button {
				text-decoration: none;
			}
		}

		.asd__day--enabled.asd__day--disabled.asd__day--scope.asd__day--no-arrival {
			button[disabled] {
				color: var(--cal-text-color) !important;
				text-decoration: none !important;
				opacity: 1 !important;
			}
		}

		.asd__day--enabled.asd__day--disabled.asd__day--not-available.asd__day--scope.asd__day--no-arrival {
			button[disabled] {
				pointer-events: none !important;
				text-decoration: line-through !important;
				color: var(--cal-text-color) !important;
				opacity: .5 !important;
			}
		}

		.asd__day--ar-pos:not(.asd__day--disabled, .asd__selected-date-one, .asd__selected-date-two, .asd__day--in-range, .asd__day--departure) {
			background: transparent !important;
			color: var(--unit-click-possible-color) !important;
		}

		.asd__day--arrival {
			position: relative;

			/*&:after {
				content: "";
				position: absolute;
				width: 20px;
				height: 100%;
				background-color: var(--unit-cal-not-available-bg);
				right: 0;
			}*/

			button {
				position: relative;
				border-radius: 50%;
				z-index: 1;
				color: #fff !important;
				background: transparent !important;
				/*background: linear-gradient(90deg, var(--unit-click-not-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important*/
			}
		}

		.asd__day--arrival:not(.asd__day--disabled) {
			button {
				border-radius: 50%;
				background: linear-gradient(90deg, var(--unit-click-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important
			}
		}


		.asd__day--departure {
			position: relative;

			/*&:after {
				content: "";
				position: absolute;
				width: 20px;
				height: 100%;
				background-color: var(--unit-cal-not-available-bg);
				left: 0;
			}*/

			button {
				text-decoration: line-through !important;
				/*background: linear-gradient(90deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-not-possible-bg) 50%) !important;*/
				background: transparent !important;
				border-radius: 50%;
				position: relative;
				z-index: 1;
				color: #fff !important;
			}
		}

		.asd__day--departure:not(.asd__day--disabled, .asd__selected-date-one) {
			button {
				background: linear-gradient(90deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-possible-bg) 50%) !important;
				color: #fff !important;
			}
		}

		.asd__day--not-available {
			background: transparent !important;
			color: var(--unit-cal-not-available-color) !important;
			opacity: 1 !important;

			button {
				text-decoration: none;
				text-decoration: line-through;
				color: var(--unit-cal-not-available-color) !important;
			}
		}

		.asd__day--disabled.asd--day--in-range {
			background: var(--unit-cal-selection-range) !important;
			opacity: 1 !important;
			/*border: 1px double var(--unit-cal-selected-border) !important;*/

		}

		.asd__day--enabled.asd__day--in-range {
			background: var(--unit-cal-selection-range) !important;
			color: var(--unit-cal-text-color-selected) !important;
			opacity: 1 !important;
			border: none !important;
			font-weight: 600;
		}

		.asd__day--enabled:not(.asd__day--disabled, .asd__day--departure, .asd__day--in-range, .asd__selected-date-one, .asd__selected-date-two) {
			background: transparent !important;
			color: var(--color-secondary) !important;
			border: none !important;


			button {
				text-decoration: none;
				font-weight: 600;
			}

			&:hover {
				button {
					background: var(--color-primary) !important;
					color: var(--unit-cal-text-color-hover) !important;
					border-radius: 50%;
				}

			}
		}

		.asd__day--enabled:not(.asd__day--disabled) {
			&:hover {
				button {
					background: var(--color-primary) !important;
					color: var(--unit-cal-text-color-hover) !important;
				}

			}
		}

		.asd__day--disabled.asd__day--hovered {
			background: inherit;
			opacity: 1 !important;
			border: inherit;
			color: inherit;
		}

		.asd__day--enabled.asd__day--hovered {
			background: inherit;
			opacity: 1 !important;
			border: inherit;
			color: inherit;

			button {
				background-color: var(--color-grey-light) !important;
				color: var(--color-black) !important;
				opacity: 1 !important;
			}
		}


		.asd__day--selected {

			color: var(--color-white) !important;
			opacity: 1 !important;
			/*border: 1px double var(--unit-cal-selected-border) !important;*/

			/*Arrow display css start*/
			&.asd__selected-date-one {
				position: relative;
				border: none !important;

				button {
					background: var(--color-primary) !important;
					border-radius: 50%;
					position: relative;
					color: #fff !important;
				}

				&:before {
					content: "";
					position: absolute;
					width: 16px;
					height: 100%;
					display: block;
					background-color: var(--unit-cal-selection-range);
					right: 0px;
				}
			}

			&.asd__selected-date-two {
				position: relative;
				border: none !important;

				button {
					background: var(--color-primary) !important;
					border-radius: 50%;
					position: relative;
					color: #fff !important;
				}

				&:before {
					content: "";
					position: absolute;
					width: 16px;
					height: 100%;
					display: block;
					background-color: var(--unit-cal-selection-range);
					left: 0px;
				}
			}
		}

		&.searchbox {
			width: 100%;
		}

	}

	.legend-cal {
		text-align: center;
		padding: 0;
		margin: 15px 0;
		color: var(--color-black);
		font-size: var(--font-size-md);

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;

			@media (max-width:767px) {
				justify-content: center;
			}
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			@media (max-width:767px) {
				justify-content: center;
			}

			/*	&:first-child {
				@media (max-width:992px) {
					width: 100%;
				}
			} */

			.day {
				width: 24px;
				height: 24px;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;

				@media (max-width:992px) {
					width: 15px;
					height: 15px;
					margin: 0 6px 0 0;
				}

				&.day-free {
					background: var(--unit-click-possible-bg);
				}

				&.day-full {
					background: var(--unit-cal-not-available-bg);
				}

				&.day-disabled {
					background: var(--unit-click-not-possible-bg);
				}

				&.day-selected-range {
					background: var(--unit-cal-selection-range);
				}

				&.day-end {
					background: linear-gradient(135deg, var(--unit-click-possible-bg) 50%, var(--unit-cal-not-available-bg) 50%) !important;
				}

				&.day-start {
					background: linear-gradient(135deg, var(--unit-cal-not-available-bg) 50%, var(--unit-click-possible-bg) 50%) !important
				}
			}
		}
	}

	.btn-bl-reset {
		text-align: center;
		padding-bottom: 10px;
		min-height: 30px;
	}
}

.tooltip.datepicker.show {
	opacity: 1 !important;
}

.datepicker {

	.tooltip-inner {
		background-color: white;
		border: 1px solid #ccc;
		font-size: 12px;
		color: #999;
		padding: 0.25rem 0.25rem;
	}

	.arrow {
		position: absolute;
		display: block;
		width: 0.8rem;
		height: 0.4rem;

		&:before {
			top: 0;
			border-width: 0.4rem 0.4rem 0;
			border-top-color: #fff;
		}
	}
}