.static-v1 {
	margin-top: var(--page-margin-top);

	.contact-form-v1 {
		border-radius: var(--border-radius);
		border: 1px solid var(--color-border);
	}

	.newsletter-post-bl {
		width: 100%;
		padding: 0;


		@media (max-width:767px) {
			padding: 0 15px;
		}

		.contact-form {
			margin-bottom: 0;

			p:last-child {
				margin-bottom: 0;
			}

			p,
			.heading1 {
				color: var(--secondary);
			}
		}
	}

	.newslet-bl {
		/*background-color: var(--color-secondary);*/
		background-color: #1d4b7d;
		padding: 25px;
		border-radius: 10px;
		max-width: 1210px;
		margin: 0 auto;
		text-align: center;

		.fa-info-btn {
			cursor: pointer;

			.fa-info-circle {
				color: var(--color-white);
				padding-left: 5px;
				position: relative;
				top: -4px;
				font-size: 14px
			}
		}

		p {
			color: #fff;
			margin-bottom: 8px;

			/*	@media (max-width:767px) {
					font-size: 12px;
				}*/
		}

		@media (max-width:992px) {
			padding: 15px;
		}

		.newsletter-text {
			color: var(--color-white);

			margin-bottom: 25px;

			label {
				color: var(--color-white);
			}

			.h3 {
				color: var(--color-white);
				font-size: 24px;
				font-weight: bold;
				font-family: var(--h4-font-family);

				span {
					background-image: url(RESOURCE/img/bg-brush.png);
					display: inline-block;
					background-repeat: no-repeat;
					background-position: bottom;
					background-size: contain;
					padding-bottom: 6px;

				}
			}

			.sub-text {
				span {
					padding: 0 10px;

					@media(max-width:767px) {
						display: block;
					}
				}
			}

		}

		h2 {
			color: var(--white);
			text-transform: uppercase;
			margin-bottom: 40px;
			font-size: 21px;

			@media (max-width:992px) {
				margin-bottom: 30px;
			}
		}

		.widgt-grey-bl {

			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			@media (max-width:767px) {
				flex-direction: column;
			}

			&.newslatter-link {
				position: relative;
				padding-bottom: 39px;

				@media(max-width:992px) {
					padding-bottom: 30px;
				}

				@media(max-width:767px) {
					padding-bottom: 0px;
				}
			}

			label {
				color: var(--color-white);
			}

			.checkbox-bl {
				position: absolute;
				bottom: 0;

				@media(max-width:768px) {
					position: relative;
					margin-bottom: 10px;
					font-size: 16px;
				}

				@media(max-width:767px) {
					.checkbox label:before {
						top: 4px;
						width: 14px;
						height: 14px;
						left: 7px;
					}
				}

				@media(max-width:767px) {
					.checkbox label:after {
						left: 5px;
					}
				}
			}

			.widt-cont-bl {

				color: var(--color-white);
				border-radius: 0.375rem;

				.form-control {
					border: none;
					background-color: var(--color-white);
					font-size: 16px;
					font-family: var(--d-font);
					height: inherit;
					padding: 0.375rem 0.75rem;
					width: 300px;
					margin-right: 15px;
					border: 1px solid #4e9fd0;

					@media (max-width:550px) {
						font-size: 13px;
						width: 220px;
						/*margin-bottom: 8px;*/
						margin-right: 0;
					}
				}
			}

			.btn-primary {
				padding: 0.375rem 0.75rem;
				border-radius: 0.375rem;

				@media (max-width:992px) {
					font-size: 16px;
				}
			}
		}
	}

	.inner-banner {
		&.mit-wasserblick {
			background-image: url(RESOURCE/img/inner-banner-wasserblick.webp);
		}

		&.sauna {
			background-image: url(RESOURCE/img/inner-banner-sauna.webp);
			background-position: center top;
		}

		&.hund {
			background-image: url(RESOURCE/img/inner-banner-hund.webp);
			background-position: center top;
		}

		&.buchbare {
			background-image: url(RESOURCE/img/inner-banner-buchbare.webp);
		}


		&.mit-wasserblick {
			background-image: url(RESOURCE/img/inner-banner-wasserblick.webp);
		}

		&.family {
			background-image: url(RESOURCE/img/inner-banner-family.webp);
			background-position: center top;
		}

		&.yoga {
			background-image: url(RESOURCE/img/inner-banner-yoga.webp);
		}

		&.angebote {
			background-image: url(RESOURCE/img/banner-angebote.webp);
		}

		&.stellenangebote {
			background-image: url(RESOURCE/img/banner-standard.webp);
		}
	}

	/*.inner-banner {
		&.mit-wasserblick {
			background-image: url(RESOURCE/img/inner-banner-wasserblick.webp);
		}

		&.sauna {
			background-image: url(RESOURCE/img/inner-banner-sauna.webp);
			background-position: center top;
		}

		&.hund {
			background-image: url(RESOURCE/img/inner-banner-hund.webp);
			background-position: center top;
		}

		&.buchbare {
			background-image: url(RESOURCE/img/inner-banner-buchbare.webp);
		}


		&.mit-wasserblick {
			background-image: url(RESOURCE/img/inner-banner-wasserblick.webp);
		}

		&.family {
			background-image: url(RESOURCE/img/inner-banner-family.webp);
			background-position: center top;
		}

		&.yoga {
			background-image: url(RESOURCE/img/inner-banner-yoga.webp);
		}

	}*/

	.list-li {
		list-style: disc;
		margin-left: 30px;
		text-align: left;

		&.two-cols {
			@media (min-width:993px) {
				-webkit-column-count: 2;
				-moz-column-count: 2;
				column-count: 2;
				-webkit-column-gap: 50px;
				-moz-column-gap: 50px;
				column-gap: 50px;
			}
		}
	}

	.blog-card {
		padding: 15px;
		box-shadow: 0 2px 20px 2px rgba(0, 0, 0, .1);
		margin-bottom: 30px;
		border-radius: 5px;

		>a {
			font-weight: bold;
			font-size: 1.25rem;
			line-height: 1.1;
			margin-bottom: 15px;
			height: 70px;
			display: inline-block;
			overflow: hidden;
		}

		dt {
			margin-bottom: 15px;
			display: inline-block;
		}

		.link {
			text-align: right;
		}
	}

	.service-slider {
		.img-block {
			height: 400px;
			margin-bottom: 20px;
			display: block;

			@media (max-width:767px) {
				height: auto;
			}

			img {

				width: 100%;
				object-fit: cover;
				height: 100%;

				@media (max-width:767px) {
					height: auto;
				}
			}
		}


		&.next-prev-btns {
			.owl-nav {
				button {
					top: 234px;

					@media (max-width:767px) {
						top: 180px;
					}
				}

				.owl-prev {
					left: -4%;
				}

				.owl-next {
					right: -4%;
				}
			}
		}

		.service-item {
			text-align: center;

			.logo {
				width: 130px;
				margin: 0 auto;
			}
		}
	}

	.logo-img-bl {
		height: 200px;
		margin-bottom: 20px;

		img {
			width: 100%;
			object-fit: contain;
			height: 100%;
		}
	}

	.buch-img-bl {
		height: 300px;
	}

	.extra-service-bl {
		.service-container {
			width: 100%;
			margin: 0 auto;

			.icon {
				img {
					width: 50px;
					height: 50px;
					display: inline-block;
				}

			}
		}


		.col-lg-3 {
			.list-li {
				margin-left: 15px;
			}
		}

		.col-lg-4,
		.col-lg-3 {
			margin-bottom: 30px;
			text-align: center;

			&.text-left {
				text-align: left;
			}

			.logo-muer {
				background-color: var(--color-white);

				img {
					object-fit: contain;
				}
			}
		}
	}

	.unver-img-bl {
		height: 350px;
	}

	.ferien-img-bl {
		height: 450px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}


	.pr2 {
		padding-right: 20px;
	}

	.pdf-main-block {
		position: relative;

		.pdf-block {
			max-width: 400px;
			position: absolute;
			right: 0;
			width: 25%;
			padding: 0 15px;
			top: 0;

			@media (max-width:992px) {
				position: inherit;
				width: auto;
				padding: 0;
			}

			.pdf-text {
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				display: inline-block;
				margin-top: 10px;
				line-height: 1.2;
			}
		}
	}




	.title {
		font-size: 1.4rem;
		font-size: var(--h3-font-size);
		padding: 10px 3px;
		font-weight: 600;
		margin-bottom: 10px;
		margin-top: 10px;
		background-color: var(--color-secondary);
		color: var(--color-white);
		text-indent: 5px;
	}

}