.feedbacks-v1 {
	.owl-carousel {
		background: var(--color-white);
		padding: 100px 50px;
		border-radius: 5px;
		margin-bottom: 50px;

		@media (max-width: 767px) {
			padding: 50px 20px;
		}

		.cl-rat {
			margin: 0 0 50px 0;

			i {
				color: var(--color-dark-blue);
			}
		}

		.owl-dots {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 50px 0 0 0;

			@media (max-width: 767px) {
				margin: 30px 0 0 0;
			}

			.owl-dot {
				width: 8px;
				height: 8px;
				background: #b5b5b5;
				margin: 0 5px;
				border-radius: 100px;

				&.active {
					background: var(--color-primary);
				}
			}
		}

		h3,
		.h3 {
			margin: 0 0 20px 0;
		}
	}
}