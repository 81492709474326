.blogentry-v1 {
	margin-top: var(--page-margin-top);

	.img-thumbnail {
		height: 100% !important;
	}

	.image-blog-bl {
		margin-bottom: 30px;
		text-align: center;

	}

	.title {
		Position: relative;
		padding-right: 90px;

		.bl-dt {
			color: var(--color-white);
			background-color: #e0880c;
			background-color: var(--color-primary);
			padding: 3px 5px;
			display: inline-block;
			margin-bottom: 15px;
			top: 5px;
			border-radius: 5px;
			right: 5px;
			position: absolute;
		}
	}

	ul {
		list-style: disc;
		padding-left: 17px;
	}

	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.tags {
		padding: 0;

		li {
			display: inline-block;

		}
	}

	.image-blog-bl {
		img {
			border-radius: 5px;
			height: inherit !important;
		}
	}

	.date {
		color: var(--color-white);
		background-color: var(--color-primary);
		padding: 3px 5px;
	}


	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

}