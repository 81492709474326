.searchbar-v1 {
	/*position: absolute;
	bottom: 8vh;*/
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	text-align: left;

	.title {
		color: var(--color-black);
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;

	}

	@media(max-width: 992px) {
		/*bottom: 12vh;*/
		bottom: -10vh;
		position: relative;
	}

	.tabs {
		padding-left: 20px;

		.tab {
			color: #ebe9e9;
			background-color: var(--color-secondary);
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding: 6px 20px;
			opacity: 1;
			cursor: pointer;
			display: inline-block;


			&.active {
				background-color: var(--color-primary);
				color: #fff;
			}

		}

	}

	#search-obj-form {
		display: none;
	}

	.search-form-area {
		/*opacity: 0.8;*/
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--color-white);

		flex-flow: wrap;

		border-radius: 0 0 8px 8px;
		margin: 0px 0px;


		.input-group {
			padding: 0px;
		}

		.form-btn {
			margin-left: 0px !important;
		}

		.searchbox-obj {
			padding-top: 4px;
			padding-left: 12px;
		}

		.form-obj-flex {

			padding: 9px 15px;

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}

			.fa {
				position: absolute;
				left: 10px;
				top: 15px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid #ddd;
				padding: 8px 10px 8px 36px;
				min-height: 40px !important;
				font-size: 14px;
				line-height: 20px;
				padding-right: 30px;

				&:focus {
					border-color: #86b7fe !important;
				}
			}

			.form-flex-area {
				position: relative;
			}

		}

		.form-flex {
			width: 30%;
			padding: 9px 15px;
			/*border-right: 1px solid #f4f4f4;*/

			/*label {
				color: var(--color-black);
				font-size: 13px;
				text-transform: uppercase;
				font-weight: 400;
				letter-spacing: 1.5px;
				margin-bottom: .5rem;


			}*/

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}


			.fa {
				position: absolute;
				left: 10px;
				top: 15px;
			}

			#datepicker-trigger-flex {

				height: auto !important;
				padding-right: 30px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid #ddd;
				padding: 8px 10px 8px 30px;
				min-height: 50px !important;
				font-size: 14px;
				line-height: 20px;
				padding-right: 30px;

				&:focus {
					border-color: #86b7fe !important;
				}
			}

			.form-flex-area {
				position: relative;
			}
		}

		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;

			@media (max-width:992px) {
				width: 100%;
				padding-bottom: 5px;
			}

			.btn {
				width: 101%;
				height: 50px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}