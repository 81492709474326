.themes-v1 {



	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none;
		height: 320px;
		margin-bottom: 30px;

		&.big-col {
			@media (min-width:768px) {
				height: 670px;
			}
		}

		&.sauna {
			img {
				object-position: right;
			}
		}
	}


	/*.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/theme-tile7.webp);
	}

	.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/theme-tile8.webp);
	}*/



	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;

		padding: 10px;

		/*@media (max-width: 767px) {
			height: 35vh;
		}*/


		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);

			@media (max-width: 767px) {
				display: none;
			}
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 100%;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;

		display: flex;
		align-items: center;
		justify-content: center;
	}



	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
		border-radius: var(--border-radius);
		position: relative;
		overflow: hidden;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			position: absolute;
		}
	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
		border-bottom-left-radius: var(--border-radius);
		border-bottom-right-radius: var(--border-radius);
		position: relative;
	}



	@media (min-width: 767px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;

		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;

		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(:hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(:hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-3--description-container {
			height: 50px;
		}

		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0px 10px;
			font-size: 20px;
			font-weight: 400;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		.theme-card-3:hover .theme-card-3--description-container {
			height: 150px;
			transition: 1s;
		}

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 766px) {

		.theme-card-1--description-container,
		.theme-card-2--description-container,
		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 10px;

		}
	}
}