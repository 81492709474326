.ueber-uns-v1 {
	margin-top: var(--page-margin-top);

	/*.contact-form-v1 {
		border-radius: var(--border-radius);
		border: 1px solid var(--color-border);
	}*/

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.bold-text {
		font-weight: bold;
	}

	.team-bl {
		border: 1px solid #ddd;
		border-radius: 4px;
		overflow: hidden;
		margin-bottom: 30px;

		&.style2 {
			.team-img {
				height: initial;
			}

			img {
				height: initial;
				object-fit: initial;
			}
		}

		.team-content {
			padding: 20px 15px;
			text-align: center;
			display: flex;
			justify-content: center;
			flex-direction: column;
			/*min-height: 160px;*/

			.name {
				font-weight: 600;
				font-size: var(--font-size-main);
			}
		}

		.team-img {
			height: 340px;

			@media (max-width:1024px) {
				height: 240px;
			}
		}
	}

	.map-bl {
		padding-top: 100%;
		position: relative;

		img {
			position: absolute;
			top: 0;
		}
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}