.unit-features-v1 {
	.feature-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--border-radius);
		border: 1px solid var(--color-border);
		background-color: var(--color-grey-light);


		@media(max-width:767px) {
			flex-wrap: wrap;
			padding: 15px;
		}

		@media(min-width:768px) {
			padding-top: 15px;
		}

		.ul-nopints {
			width: 100%;
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;

			@media(max-width:767px) {
				max-width: 100%;
			}

			li {
				display: block;
				text-align: center;
				font-size: 14px;
				margin-bottom: 5px;

				.prop-bl {
					display: flex;
					justify-content: center;
					gap: 3px;
				}

				@media (min-width:1025px) {
					width: 16%;
					margin-bottom: 15px;
				}

				@media (max-width:1024px) {
					width: 24%;
					margin-bottom: 15px;
				}


				@media(max-width:767px) {

					margin-right: 5px;
					width: 32%;
				}

				@media(max-width:460px) {

					margin-right: 5px;
					width: 45%;
				}


				@media(max-width:560px) {
					font-size: 14px;
				}

				span {
					display: block;
				}

				.fa-icon {
					width: 27px;
					height: 27px;
					background-repeat: no-repeat;
					display: inline-block;
					background-position: center;
					margin: 0 auto;
					opacity: .8;

					@media(max-width:767px) {
						width: 18px;
						height: 18px;
					}

					&.fa-sauna {
						background-image: url(RESOURCE/img/icon-sharp.svg);
					}

					&.fa-fireside {
						background-image: url(RESOURCE/img/icon-fire.svg);
					}

					&.fa-washer {
						background-image: url(RESOURCE/img/icon-washing.svg);
					}

					&.fa-dryer {
						background-image: url(RESOURCE/img/icon-dryer.svg);
					}
				}



				&.full-width {
					@media(max-width:767px) {
						width: 100%;
					}
				}

				i {
					width: 100%;

					@media (min-width:768px) {
						font-size: 25px;

						text-align: center;
						margin-bottom: 6px;
					}

					&.text-green {
						color: var(--color-secondary);
					}

					@media(max-width:767px) {
						/*margin-right: 5px;*/

						width: 100%;
					}
				}

				.fa-stack {
					padding-left: 5px;

					@media (min-width:768px) {
						margin: 0 auto;
						display: block;
						margin-bottom: 3px;
					}

					@media(max-width:767px) {
						width: 16px;
						position: relative;
						left: 0px;
						top: -1px;
					}
				}
			}
		}

		.rating {
			i {
				color: var(--color-secondary);
			}
		}
	}
}