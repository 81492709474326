.search-result-header-v1 {
	.sorting-trigger {
		width: 45px;
		height: 45px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
		text-align: center;
		line-height: 45px;
		font-size: 28px;
		border-radius: 3px;
		border: 1px solid hsla(0, 0%, 49%, .2);
		margin: -26px -8px 0 0;
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		span {
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}

		.icon-sort {
			background-image: url(RESOURCE/img/arrow-down-short-wide-solid.svg);
			width: 28px;
			height: 28px;
			display: inline-block;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.found {
		font-weight: 400;
	}

	.srt-bl-top {
		.flex-align-center {

			.btn-filter {
				font-size: var(--font-size-md);
				padding-right: 10px;
				display: inline-block;
			}
		}
	}

	.srt-bl-top {
		padding-top: 20px;
		padding-bottom: 20px;
	}

}