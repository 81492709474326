.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.kontact {
			background-image: url(RESOURCE/img/banner-vermietungsbuero.webp);
		}

		/*&.kontact {
			background-image: url(RESOURCE/img/inner-banner-kontact.webp);
		}*/
	}

	.office-locations-bl {
		p {
			strong {
				font-size: 1.125rem;
			}
		}
	}
}