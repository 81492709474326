.unit-book-now-v1 {
	.col-12 {
		margin-bottom: 15px !important;

		&:last-child {
			margin-bottom: 0 !important;
		}
	}

	.fa-chevron-right {
		font-size: 14px;
	}

	.fa-heart {
		color: var(--color-primary);
	}

}