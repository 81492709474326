.unit-title-v1 {
	h1 {
		display: flex;
		flex-wrap: wrap;

		span {
			margin-right: 5px;
		}
	}

	.dtv {
		font-size: 14px;
		font-family: var(--font-family-main);
		margin-left: 5px;
	}

	.title-stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
		margin-right: 5px;

		.fa-star-o {
			display: none;
		}

		.fa-star {
			width: 20px;
			height: 20px;
			background-size: cover;
			background-image: url(RESOURCE/img/icon_star.webp);

			&:before {
				display: none;
			}
		}
	}
}